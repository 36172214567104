import React from 'react';
import noEvents from '../img/no-events.svg';
import LocationStepper from './LocationStepper';

const ContainerEvents = props => {
    const initalItemsNumber = 100;

    return (
        <>
            <div className="d-flex-columns pb-4 px-0" style={{ paddingTop: '3.5rem' }}>
                <div>
                    <LocationStepper initalItemsNumber={initalItemsNumber} locations={props.container.locations} />
                </div>
            </div>
            {(!props.container.locations || props.container.locations.length === 0) && (
                <img className="mt-n3 mb-3" src={noEvents} alt="No Events" />
            )}
        </>
    );
};

export default ContainerEvents;
