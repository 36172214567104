import React from 'react';

const ArrowIcon = props => {
    return (
        <svg
            className={`${props.open ? 'rotate-icon' : ''} cursor-pointer`}
            xmlns="http://www.w3.org/2000/svg"
            width="12.822"
            height="8.111"
            viewBox="0 0 12.822 8.111"
            onClick={props.onClick}
        >
            <g id="_16px__Down" data-name="16px__Down" transform="translate(1.411 1.411)">
                <g id="Layer_1_1_" transform="translate(0 0)">
                    <path
                        id="Path_1119"
                        data-name="Path 1119"
                        d="M10,0,5,5.7,0,0"
                        fill="none"
                        stroke="#196eb1"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                    />
                </g>
            </g>
        </svg>
    );
};

export default ArrowIcon;
