import * as React from 'react';

import { Switch, Route, BrowserRouter } from 'react-router-dom';

import ContainersPanel from '../../containers/ContainersPanel';
// import FakeMagaya from '../../FakeMagaya';

const MainLayout = props => (
    <BrowserRouter basename="/">
        <Switch>
            <Route exact path="/track/:enc" component={ContainersPanel} />
        </Switch>
    </BrowserRouter>
);

export default MainLayout;
