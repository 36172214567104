/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import moment from 'moment';

import useHttp from '../../hooks/http';
import ContainerMainEvents from './ContainerMainEvents';
import ContainerEvents from './ContainerEvents';
import TerminalInformation from './terminal-information/TerminalInformation';

import Loading from '../layouts/main-layout/components/Loading';

const ContainerDetails = props => {
    const containerElement = React.useRef();
    const { isLoading, error } = useHttp();

    const [containerDetails, setContainerDetails] = React.useState();

    const transformLocations = locations => {
        const result = [];
        if (!locations) {
            return [];
        }
        const totalDays = 0;
        for (let index = 0; index < locations.length; index += 1) {
            const currentLocation = locations[index];
            currentLocation.expanded = index !== 0;
            currentLocation.dayPos = totalDays;
            currentLocation.icon = index !== 0 ? 'k-step-indicator-icon k-icon k-i-route' : 'k-icon k-i-start';
            currentLocation.icon = index + 1 === locations.length ? 'k-icon k-i-end' : currentLocation.icon;

            currentLocation.label = currentLocation.name;
            if (currentLocation.name === 'In Transit') {
                currentLocation.duration = true;
                currentLocation.disabled = false;
            } else if (currentLocation.events && currentLocation.events.length) {
                const vesselList = [
                    ...new Set(
                        currentLocation.events
                            .filter(e => e.vessel && e.vessel.trim().toLowerCase() !== 'unknown')
                            .map(e => e.vessel)
                    )
                ];
                if (vesselList.length) {
                    currentLocation.vesselList = vesselList.join(', ');
                }
            }
            result.push(currentLocation);
        }
        return result;
    };

    const getLastPosDays = locations => {
        if (!locations || !locations.length) {
            return 0;
        }
        const allEvents = locations.map(l => l.events || []).flat();
        let lastPos = 0;
        let durationDays = 0;
        if (allEvents.length) {
            durationDays = moment(allEvents[allEvents.length - 1].date).diff(moment(allEvents[0].date), 'days') + 1;
            let lastIndex = allEvents.findIndex(e => !e.done);
            if (lastIndex !== -1) {
                lastIndex = lastIndex > 0 ? lastIndex - 1 : lastIndex;
                lastPos = moment(allEvents[lastIndex].date).diff(moment(allEvents[0].date), 'days');
            }
        }
        const toNow = moment(moment()).diff(moment(allEvents[0].date), 'days');
        lastPos = toNow < durationDays ? toNow : durationDays;
        return { lastPos, durationDays };
    };

    React.useEffect(() => {
        if (props.container) {
            const posData = getLastPosDays(props.container.locations);
            const locations = transformLocations(props.container.locations);
            const { durationDays } = posData;
            const positionDays = posData.lastPos;
            setContainerDetails({
                ...props.container,
                tracking: props.container.containerStatus === 1,
                finished: props.container.containerStatus === 3,
                ...{ locations, durationDays, positionDays }
            });
        }
    }, [props.container]);

    return (
        (isLoading && <Loading />) ||
        (containerDetails && (
            <div ref={containerElement}>
                <div className=" container containerInfo">
                    <div className="row" style={{ width: '100%' }}>
                        <div className="leftInfo col-12 col-lg-5">
                            <ContainerMainEvents container={containerDetails} />

                            <TerminalInformation container={props.container} />
                        </div>
                        <div className="rightInfo col-12 col-lg-7">
                            {containerDetails && containerDetails.locations.length && (
                                <ContainerEvents container={containerDetails} />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        )) ||
        (error && <p> ERROR FOUND: {error} </p>) ||
        null
    );
};

export default ContainerDetails;
