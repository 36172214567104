import React from 'react';
import { useTranslation } from 'react-i18next';

const TerminalInformationSearates = props => {
    const { t } = useTranslation('containersApp');
    return (
        <div className="terminal-information-container__header">
            {(props?.container?.subscriptionType ?? 'STANDARD') === 'STANDARD' ? (
                <div className="terminal-information-container__header__caption">
                    <p className="terminal-information-container__header__caption__sea">{t('NO_TERMINAL_DATA')}</p>
                    <button
                        className="terminal-information-container__header__caption__sea-link"
                        type="button"
                        onClick={() =>
                            window.open(
                                'https://docs.google.com/spreadsheets/d/1SRSHNgiUfDX_Zew7W6VBzT2N4V1gsXp0UJVhBfezKbw/edit#gid=271075432',
                                '_blank'
                            )
                        }
                    >
                        {t('LEARN_MORE')}
                    </button>
                </div>
            ) : (
                <div className="terminal-information-container__header__caption">
                    <p className="terminal-information-container__header__caption__sea">{t('NO_TERMINAL_INFO')}</p>
                    <button
                        className="terminal-information-container__header__caption__sea-link"
                        type="button"
                        onClick={() =>
                            window.open(
                                'https://docs.google.com/spreadsheets/d/1SRSHNgiUfDX_Zew7W6VBzT2N4V1gsXp0UJVhBfezKbw/edit#gid=271075432',
                                '_blank'
                            )
                        }
                    >
                        {t('LEARN_MORE_TERMINAL')}
                    </button>
                </div>
            )}
        </div>
    );
};

export default TerminalInformationSearates;
