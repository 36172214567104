import React from 'react';
import { useTranslation } from 'react-i18next';

const ContainerHeader = ({ containerDetails }) => {
    const { t } = useTranslation('containersApp');

    return (
        <div className="d-flex py-3 px-0 mb-3 details-header">
            <div className="container d-flex justify-content-between" style={{ maxWidth: '1250px' }}>
                <p className="h4 font-weight-lighter mb-0 text-font-color">
                    {containerDetails && containerDetails.containerId}
                </p>
                {containerDetails && <span className="other-blue-font font-weight-bold">{t('TRACKING_EVENTS')} </span>}

                {/*  {containerDetails && containerDetails.finished && <span> ENDED </span>} */}
            </div>
        </div>
    );
};

export default ContainerHeader;
