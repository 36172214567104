import { useReducer, useCallback } from 'react';
import axios from 'axios';

const initialState = {
    loading: false,
    error: null,
    data: null,
    extra: null,
    identifier: null
};

const httpReducer = (curHttpState, action) => {
    switch (action.type) {
        case 'SEND':
            return {
                loading: true,
                error: null,
                data: null,
                extra: null,
                identifier: action.identifier
            };
        case 'RESPONSE':
            return {
                ...curHttpState,
                loading: false,
                data: action.responseData,
                extra: action.extra,
                csErrors: action.csErrors,
                csWarning: action.csWarning,
                unauthorized: action.unauthorized,
                identifier: action.identifier
            };
        case 'ERROR':
            return {
                loading: false,
                error: action.errorMessage,
                data: action.responseData,
                extra: action.extra,
                csErrors: action.csErrors,
                csWarning: action.csWarning,
                unauthorized: action.unauthorized,
                identifier: action.identifier
            };
        case 'CLEAR':
            return initialState;
        default:
            throw new Error('Should not be reached!');
    }
};

const useHttp = () => {
    const [httpState, dispatchHttp] = useReducer(httpReducer, initialState);
    const clear = useCallback(() => dispatchHttp({ type: 'CLEAR' }), []);

    const sendRequest = useCallback((url, method, body, reqExtra, reqIdentifier, headers) => {
        dispatchHttp({ type: 'SEND', identifier: reqIdentifier });

        axios({
            method,
            url
            // data: body,
            // headers: {
            //     'Content-Type': 'application/json',
            //     ...headers
            // }
        })
            .then(response => {
                dispatchHttp({
                    type: 'RESPONSE',
                    responseData: response.data,
                    extra: reqExtra,
                    identifier: reqIdentifier,
                    csErrors: response.data.errors,
                    csWarning: response.status === 204,
                    unauthorized: null
                });
            })
            .catch(errorResponse => {
                let csErrors = '';
                let csWarning = '';
                const resp = errorResponse.response || null;
                if (resp && resp.data) {
                    if (resp.data.exception && resp.data.exception.length) {
                        csErrors = resp.data.exception[0].error;
                    }
                    if (resp.data.objects && resp.data.objects.code && resp.data.objects.code === 'ECONNREFUSED') {
                        csErrors = 'There was a problem contacting the Central Service. Try again later.';
                    }
                    if (resp.status === 401 && resp.data.objects && resp.data.objects === 'Invalid_Api_Key') {
                        csErrors =
                            "The API key is not valid or doesn't exist. Request a new key in the Magaya Communication Suite Configuration.";
                    } else if (resp.status === 404 && resp.data.objects === 'No Containers Found') {
                        csWarning =
                            'There are not tracked containers. Select some shipments in Magaya and click on Add or Track.';
                    }
                }
                if (errorResponse.isAxiosError) {
                    errorResponse = errorResponse.message;
                }
                dispatchHttp({
                    type: 'ERROR',
                    extra: reqExtra,
                    identifier: reqIdentifier,
                    unauthorized: Boolean(
                        errorResponse.response &&
                            errorResponse.response.status === 401 &&
                            errorResponse.response.data &&
                            errorResponse.response.data.objects &&
                            errorResponse.response.data.objects === 'Inactive'
                    ),
                    csErrors,
                    csWarning,
                    errorMessage:
                        (typeof errorResponse === 'string' && errorResponse) ||
                        errorResponse.error ||
                        'Something went wrong!'
                });
            });
    }, []);

    return {
        isLoading: httpState.loading,
        data: httpState.data,
        error: httpState.error,
        csErrors: httpState.csErrors,
        csWarning: httpState.csWarning,
        unauthorized: httpState.unauthorized,
        sendRequest,
        reqExtra: httpState.extra,
        reqIdentifier: httpState.identifier,
        clear
    };
};

export default useHttp;
