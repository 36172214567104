import i18next from 'i18next';
import en from './i18n/en';

i18next.addResourceBundle('en', 'containersApp', en);

const ContainersAppConfig = {
    requestStatus: {
        UPDATED: { value: 0, description: '' },
        ERROR: { value: 1, description: '' },
        WARNING: { value: 2, description: '' },
        PENDING: { value: 3, description: 'TRACKING_PROGRESS' },
        REQ_TIME_OUT: { value: 4, description: 'CALL_TIME_OUT' }
    },

    sectionNavigation: {
        TRACKING: 'tracking',
        LATEST: 'latest'
    },

    eventDefinitions: [
        { key: 'CEP', text: 'CONTAINER_EMPTY_TO_SHIPPER' },
        { key: 'LTS', text: 'LAND_TRANSSHIPMENT' },
        { key: 'BTS', text: 'BARGE_TRANSSHIPMENT' },
        { key: 'CPS', text: 'CONTAINER_PICKUP_AT_SHIPPER' },
        { key: 'CGI', text: 'CONTAINER_ARRIVAL_AT_FIRST_POL' },
        { key: 'CLL', text: 'CONTAINER_LOADED_AT_FIRST_POL' },
        { key: 'VDL', text: 'VESSEL_DEPARTURE_FROM_FIRST_POL' },
        { key: 'VAT', text: 'VESSEL_ARRIVAL_PORT' },
        { key: 'CDT', text: 'CONTAINER_DISCHARGE_PORT' },
        { key: 'TSD', text: 'TRANSSHIPMENT_DELAY' },
        { key: 'CLT', text: 'CONTAINER_LOADED_AT_PORT' },
        { key: 'VDT', text: 'VESSEL_DEPARTURE_FROM_TS' },
        { key: 'VAD', text: 'VESSEL_ARRIVAL_AT_FINAL_POD' },
        { key: 'CDD', text: 'CONTAINER_DISCHARGE_AT_FINAL_POD' },
        { key: 'CGO', text: 'CONTAINER_DEPARTURE_FROM_FINAL_POD' },
        { key: 'CDC', text: 'CONTAINER_DELIVERY_TO_CONSIGNEE' },
        { key: 'CER', text: 'CONTAINER_EMPTY_RETURN_TO_DEPOT' },
        { key: 'TTT', text: 'IN_TRANSIT' },
        { key: 'defaultItem', text: 'VIEW_ALL' }
    ]
};

export default ContainersAppConfig;
