import React from 'react';
import { Step } from '@progress/kendo-react-layout';
import StepperEventsByLocation from './StepperEventsByLocation';

const LocationStep = props => {
    const newProps = { ...props };
    const { label, vesselList, icon } = props;
    delete newProps.label;
    delete newProps.vesselList;
    delete newProps.icon;
    delete newProps.dayPos;
    return (
        <Step {...newProps}>
            <span className="i-route-m6">
                <span
                    className={`${icon} ${
                        newProps.current ? 'k-i-current' : newProps.events[0].done ? 'k-i-actual' : 'k-i-estimated'
                    }`}
                />
                <span>{label}</span>
                {vesselList && <span className="vessel-label">(Vessels: {vesselList})</span>}
                <span>
                    <StepperEventsByLocation events={newProps.events} />
                </span>
            </span>
        </Step>
    );
};

export default LocationStep;
