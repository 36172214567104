import React from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import locationTag from '../img/location.svg';

const StepperEventsByLocation = props => {
    const { t } = useTranslation('containersApp');
    // events = [{description, date}]
    return (
        <div className={props.events[0].done ? 'py-2 px-4' : 'py-2 px-4 stepper-estimated'}>
            {props.events.map(event => (
                <div className="d-flex justify-content-between mb-2" key={`${event.description}${event.date}`}>
                    <div>
                        {event.icon && <span className={event.icon} />}
                        <span className={event.icon ? 'f-14 last-event' : 'f-14'}>
                            <p style={{ display: 'inline', whiteSpace: 'pre-line', marginRight: 5 }}>
                                {event.description} {event.icon && <img src={locationTag} alt="current" />}
                            </p>
                        </span>{' '}
                    </div>
                    <span className={event.icon ? 'f-14 no-margin' : 'f-14'}>
                        {event.icon ? (
                            <span className="step-tag-current">{t('CURRENT')}</span>
                        ) : event.done ? (
                            <span className="step-tag-actual">{t('ACTUAL')}</span>
                        ) : (
                            <span className="step-tag-estimated">{t('ESTIMATED')}</span>
                        )}
                        {moment(event.date).format('MM/DD/yyyy')}
                    </span>
                </div>
            ))}
        </div>
    );
};

export default StepperEventsByLocation;
