import React, { useState, useEffect } from 'react';
import HoldAtTerminal from './HoldAtTerminal';
import PortOfLoading from './PortOfLading';
import TerminalInformationSearates from './TerminalInformationSearates';
import TerminalInformationTerminal49 from './TerminalInformationTerminal49';
import TerminalInformationNotAvailable from './TerminalInformationNotAvailable';

const TerminalInformation = props => {
    const [open, setOpen] = useState(false);
    const terminal49 = 'Terminal49';

    const onClick = () => {
        setOpen(!open);
    };

    useEffect(() => {
        setOpen(props.container && props.container.vendorId === terminal49);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.container]);

    return (
        <div className="terminal-information-container">
            {props.container && props.container.vendorId === terminal49 ? (
                props.container.scac && props.container.scac === 'AUTO' ? (
                    <TerminalInformationNotAvailable />
                ) : (
                    <TerminalInformationTerminal49 open={open} onClick={onClick} container={props.container} />
                )
            ) : (
                <TerminalInformationSearates container={props.container} />
            )}

            {props.container && props.container.vendorId === terminal49 && open && props.container.scac !== 'AUTO' && (
                <div className="terminal-information-container__body">
                    <PortOfLoading container={props.container} />
                    <HoldAtTerminal container={props.container} />
                </div>
            )}
        </div>
    );
};

export default TerminalInformation;
