import React from 'react';

const TerminalInformationNotAvailable = props => {
    return (
        <div className="terminal-information-container__not-available">
            <div className="terminal-information-container__not-available__header">
                <p className="terminal-information-container__not-available__header__text">
                    Terminal information not available.
                </p>
                <p className="terminal-information-container__not-available__header__text">
                    SCAC code in the shipment was not found.
                </p>
                <button
                    className="terminal-information-container__not-available__header__link"
                    type="button"
                    onClick={() =>
                        window.open(
                            'https://docs.google.com/spreadsheets/d/1SRSHNgiUfDX_Zew7W6VBzT2N4V1gsXp0UJVhBfezKbw/edit#gid=271075432',
                            '_blank'
                        )
                    }
                >
                    Learn more about the terminal data
                </button>
            </div>
        </div>
    );
};

export default TerminalInformationNotAvailable;
