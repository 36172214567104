import React from 'react';
import moment from 'moment';
import { Slider, SliderLabel } from '@progress/kendo-react-inputs';
import { useTranslation } from 'react-i18next';
import noEvents from '../img/no-events.svg';

const ContainerEvents = props => {
    const { t } = useTranslation('containersApp');
    // const [value, setValue] = React.useState(0);

    return (
        <>
            <div className="d-flex-columns px-0 pt-7">
                <div className="pb-3">
                    <p className="p2 mb-0 other-gray-font font-weight-bold">{t('CARRIER_SCAC_CODE')}</p>
                    <p className="p2 mb-0 other-gray-font">
                        {`${props.container.exportingCarrier || '-'} | ${props.container.scac || '-'} `}
                    </p>
                </div>
                {props.container.locations && props.container.locations.length > 0 && (
                    <div>
                        <div
                            className="d-flex justify-content-between mb-3 font-14px pl-pr-2rem"
                            style={{ alignItems: 'flex-end' }}
                        >
                            <div className="d-flex flex-column align-items-start w-25">
                                <span className="app-font-blue text-uppercase">
                                    {props.container.shipmentEstimatedDeparture?.location?.name || '-'}
                                </span>
                                <span className="app-font-gray">{t('DEPARTURE')}</span>
                            </div>
                            <div className="d-flex flex-column align-items-end w-25">
                                <span className="app-font-blue text-uppercase">
                                    {props.container.shipmentEstimatedArrival?.location?.name || '-'}
                                </span>

                                <span className="app-font-gray">{t('ARRIVAL')}</span>
                            </div>
                        </div>
                        <div className="d-flex pl-pr-2rem">
                            <span className="slider-actual" />
                            <Slider
                                className="mb-2 w-100"
                                min={0}
                                max={props.container.durationDays}
                                step={1}
                                value={props.container.positionDays}
                            >
                                {props.container.locations.map((ev, index) => (
                                    <SliderLabel
                                        key={index}
                                        position={ev.dayPos}
                                        onClick={() => {
                                            // setValue(index);
                                        }}
                                    />
                                ))}
                            </Slider>
                            <span className="slider-estimated" />
                        </div>

                        <div className="d-flex justify-content-between mb-3 font-14px pl-pr-2rem">
                            <div className="d-flex flex-column align-items-start w-25">
                                <span className="app-font-gray">
                                    {props.container.shipmentEstimatedDeparture?.dateTime
                                        ? moment(props.container.shipmentEstimatedDeparture.dateTime).format(
                                              'MM/DD/yyyy'
                                          )
                                        : '-/-/-'}
                                </span>
                                <span className="f-14">
                                    {props.container.shipmentEstimatedDeparture?.actual ? (
                                        <span className="step-tag-actual" style={{ marginRight: 0 }}>
                                            {t('ACTUAL')}
                                        </span>
                                    ) : (
                                        <span className="step-tag-estimated" style={{ marginRight: 0 }}>
                                            {t('ESTIMATED')}
                                        </span>
                                    )}
                                </span>
                            </div>
                            <div className="d-flex flex-column align-items-end w-25">
                                <span className="app-font-gray">
                                    {props.container.shipmentEstimatedArrival?.dateTime
                                        ? moment(props.container.shipmentEstimatedArrival.dateTime).format('MM/DD/yyyy')
                                        : '-/-/-'}
                                </span>
                                <span className="f-14">
                                    {props.container.shipmentEstimatedArrival?.actual ? (
                                        <span className="step-tag-actual" style={{ marginRight: 0 }}>
                                            {t('ACTUAL')}
                                        </span>
                                    ) : (
                                        <span className="step-tag-estimated" style={{ marginRight: 0 }}>
                                            {t('ESTIMATED')}
                                        </span>
                                    )}
                                </span>
                            </div>
                        </div>
                    </div>
                )}
            </div>
            {(!props.container.locations || props.container.locations.length === 0) && (
                <img className="mt-n3 mb-3" src={noEvents} alt="No Events" />
            )}

            {props.container.lastEvent && (
                <div className="d-flex-columns pb-2 px-0 pt-2">
                    <p className="p2 other-gray-font mb-0 font-weight-bold">{t('LAST_EVENT')}</p>
                    <p style={{ fontSize: '14px' }}> {props.container.lastEvent.description} </p>
                </div>
            )}
        </>
    );
};

export default ContainerEvents;
