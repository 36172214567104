import React from 'react';
import { useTranslation } from 'react-i18next';
import ArrowIcon from '../../shared/ArrowIcon';

const TerminalInformationTerminal49 = props => {
    const { t } = useTranslation('containersApp');
    return (
        <div className="terminal-information-container__header">
            <div className="terminal-information-container__header__caption terminal49">{t('TERMINAL_INFO')}</div>
            <div className="terminal-information-container__header__sub-caption">
                <ArrowIcon open={props.open} onClick={props.onClick} />
            </div>
        </div>
    );
};

export default TerminalInformationTerminal49;
