import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import ArrowIcon from '../../shared/ArrowIcon';

const HoldAtTerminal = props => {
    const { t } = useTranslation('containersApp');
    const [open, setOpen] = useState(true);
    const dataAvailable =
        props.container &&
        props.container.terminalAttributes &&
        props.container.terminalAttributes.holdsAtPodTerminal &&
        props.container.terminalAttributes.holdsAtPodTerminal.length > 0;

    useEffect(() => {
        setOpen(dataAvailable);
    }, [props.container, dataAvailable]);

    return (
        <div className="hold-at-terminal-container">
            {dataAvailable && (
                <div className="hold-at-terminal-container__header">
                    <div className="hold-at-terminal-container__header__caption">{t('HOLD_TERMINAL')}</div>
                    <div className="hold-at-terminal-container__header__sub-caption">
                        {dataAvailable && <ArrowIcon open={open} onClick={() => setOpen(!open)} />}
                    </div>
                </div>
            )}
            {open && (
                <div className="hold-at-terminal-container__body">
                    {dataAvailable &&
                        props.container.terminalAttributes.holdsAtPodTerminal.map((hold, index) => (
                            <h4 className="hold-at-terminal-container__body__line">
                                <span className="hold-at-terminal-container__body__line__caption">{hold.name}</span>
                                <span className="hold-at-terminal-container__body__line__sub-caption">
                                    {hold.description}
                                </span>
                                <span className="hold-at-terminal-container__body__line__sub-caption-status">
                                    {hold.status}
                                </span>
                            </h4>
                        ))}
                </div>
            )}
        </div>
    );
};

export default HoldAtTerminal;
