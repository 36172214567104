import React from 'react';
import { useParams } from 'react-router-dom';
import Loading from '../layouts/main-layout/components/Loading';
import ContainerMainDetails from '../container-details/ContainerMainDetails';
import ContainerHeader from '../container-details/ContainerHeader';
import useHttp from '../../hooks/http';

const ContainersPanel = () => {
    const [containerData, setContainerData] = React.useState();
    const { enc } = useParams();
    const baseUrl = process.env.REACT_APP_API_BASE_URL;

    const { isLoading, error, data, sendRequest, reqIdentifier } = useHttp();

    React.useEffect(() => {
        // this is not used right now, the data binding to the details is the data coming from the grid
        // once separated dto grid and dto details we will use this.
        if (enc) {
            // const baseUrl = process.env.REACT_APP_API_BASE_URL;
            const URI = `${baseUrl}/api/GetContainerLinkInfoHttpTrigger?code=${process.env.REACT_APP_AZURE_CODE}&enc=${enc}`;
            sendRequest(
                // `${props.baseUrl}/containers/${props.container.containerId}?condition=networkId=${props.networkId}`,
                URI,
                'GET',
                null,
                null,
                'LOAD_CONTAINER_DETAILS'
            );
        }
    }, [baseUrl, enc, sendRequest]);

    React.useEffect(() => {
        if (!isLoading && !error && reqIdentifier === 'LOAD_CONTAINER_DETAILS') {
            if (data && data.length && data[0]) {
                setContainerData({
                    ...data[0],
                    tracking: data[0].containerStatus === 1,
                    finished: data[0].containerStatus === 3
                });
            }
        }
    }, [data, error, isLoading, reqIdentifier]);

    return (
        <>
            {isLoading && <Loading />}

            <ContainerHeader containerDetails={containerData} />
            <ContainerMainDetails baseUrl={baseUrl} container={containerData} />
        </>
    );
};

export default ContainersPanel;
