import React from 'react';
import './App.scss';
import MainLayout from './components/layouts/main-layout/MainLayout';
import './config/ContainersAppConfig';

function App() {
    return (
        // <Router>
        <>
            <MainLayout />
        </>

        // </Router>
    );
}

export default App;
