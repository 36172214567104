/* eslint-disable no-plusplus */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Stepper } from '@progress/kendo-react-layout';
import LocationStep from './LocationStep';
import ArrowIcon from '../shared/ArrowIcon';

const getLocationInfo = locations => {
    let lastPos = 0;
    for (let n = locations.length - 1; n >= 0; n--) {
        const l = locations[n];
        lastPos = n;
        for (let i = l.events.length - 1; i >= 0; i--) {
            if (l.events[i].done) {
                l.events[i].icon = 'k-icon current-location-stepper';
                n = -1;
                break;
            }
        }
    }
    const actual = locations.slice(0, lastPos);
    const current = locations.slice(lastPos, lastPos + 1);
    const estimated = locations.slice(lastPos + 1);
    return { actual, current, estimated, lastPos };
};

const LocationStepper = props => {
    const { t } = useTranslation('containersApp');
    const { actual, current, estimated, lastPos } = getLocationInfo(props.locations);
    const [openActual, setOpenActual] = useState(false);
    const [openEstimated, setOpenEstimated] = useState(true);

    return (
        <div>
            {!!actual.length && (
                <div className="k-stepper">
                    <div className="stepper-container k-step-done px-3 ">
                        <p className="stepper-header py2">{t('ACTUAL_EVENTS')}</p>
                        <ArrowIcon open={openActual} onClick={() => setOpenActual(!openActual)} />
                    </div>
                </div>
            )}
            {openActual && !!actual.length && (
                <Stepper orientation="vertical" value={lastPos} items={actual} item={LocationStep} />
            )}

            <Stepper orientation="vertical" items={current} item={LocationStep} />

            {!!estimated.length && (
                <div className="k-stepper">
                    <div className="stepper-container px-3 ">
                        <p className="stepper-header stepper-estimated">{t('ESTIMATED_EVENTS')}</p>
                        <ArrowIcon open={openEstimated} onClick={() => setOpenEstimated(!openEstimated)} />
                    </div>
                </div>
            )}
            {openEstimated && !!estimated.length && (
                <Stepper orientation="vertical" value="-1" items={estimated} item={LocationStep} />
            )}
        </div>
    );
};

export default LocationStepper;
