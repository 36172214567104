const locale = {
    YES: 'Yes',
    NO: 'No',
    WARNING_OK_TEXT: 'OK',
    SUCCESS_DIALOG_DESCRIPTION: 'Operation was performed successfully',
    CONTAINER: 'Container',
    CARRIER: 'Carrier',
    CONSIGNEE: 'Consignee',
    ARRIVAL: 'Arrival',
    DEPARTURE: 'Departure',
    SHIPMENT: 'Shipment',
    SHIPPING_CONTAINER: 'Shipping Container',
    ALL_CONTAINERS: 'All Containers',
    TRACKING: 'Tracking',
    TRACK: 'Track',
    TRACKING_CONTAINERS: 'Tracking Containers',
    FILTER_DATES: 'Filter By Dates',
    TRACK_SELECTED: 'Track Selected',
    TRACKING_EVENTS: 'Tracking Events',
    CARRIER_NAME: 'Carrier Name',
    VESSEL_FLAG: 'Vessel Flag',
    VESSEL_NAME: 'Vessel Name',
    DESTINATION_PORT: 'Destination Port',
    ORIGIN_PORT: 'Origin Port',
    EXPAND_ALL: 'Expand All',
    COLLAPSE_ALL: 'Collapse All',
    CLEAR_ALL: 'Clear All',
    CONTAINER_NUMBER: 'Container Number',
    ORIGIN: 'Origin',
    DESTINATION: 'Destination',
    ARRIVAL_DATE: 'Estimated Arrival Date',
    DEPARTURE_DATE: 'Departure Date',
    CARRIER_SCAC: 'Carrier SCAC Code',
    BILL_OF_LADING_NUMBER: 'Bill of Lading',
    MASTER_BOL: 'Master BOL',
    HOUSE_BOL: 'House BOL',
    TITLE_FILTER: 'Filters',
    APPLY_FILTER: 'Apply Filters',
    LATEST: 'Latest',
    LATEST_CONTAINERS_ADDED: 'Latest Containers Added',
    FINISHED: 'Finished',
    PORT: 'Port',
    START: 'Start',
    END: 'End',
    START_DATE: 'Start Date',
    END_DATE: 'End Date',
    ROUTING_NUMBERS: 'Routing',
    ALL: 'All',
    ESTIMATED_ARRIVAL_DATE: 'Estimated Arrival Date',
    NOT_FILTERED: 'Not Filtered',
    SELECT_VALUE: 'Select Value',
    VIEW_ALL: 'View All',
    LAST_24_HRS: 'Last 24 Hrs',
    LAST_72_HRS: 'Last 72 Hrs',
    LAST_WEEK: 'Last Week',
    LAST_2_WEEKS: 'Last 2 Weeks',
    LAST_MONTH: 'Last Month',
    NEXT_24_HRS: 'Next 24 Hrs',
    NEXT_72_HRS: 'Next 72 Hrs',
    NEXT_WEEK: 'Next Week',
    NEXT_2_WEEKS: 'Next 2 Weeks',
    NEXT_MONTH: 'Next Month',
    LATEST_STATUS: 'Latest Status',
    LATEST_EVENT: 'Latest Event',
    LAST_TIME_UPDATE: 'Last Time Updated',
    HOUSE_NAME: 'House Name',
    FILE_NAME: 'File Name',
    MASTER_NAME: 'Master Name',
    REFRESH: 'Refresh',
    UPDATE_SELECTED: 'Update Selected',
    UPDATE_ALL: 'Update All',
    UPDATE: 'Update',
    FILTERED: 'Filtered',
    FORWARDING_AGENT: 'Forwarding Agent',
    DESTINATION_AGENT: 'Destination Agent',
    SHIPPER: 'Shipper',
    SHIPPER_NAME: 'Shipper',
    VOYAGE: 'Voyage',
    UNKNOWN: 'Unknown',
    REMOVE: 'Remove',
    LAND_TRANSSHIPMENT: 'Land Transshipment',
    BARGE_TRANSSHIPMENT: 'Barge Transshipment',
    CONTAINER_EMPTY_TO_SHIPPER: 'Container empty to shipper',
    CONTAINER_PICKUP_AT_SHIPPER: 'Container pickup at shipper',
    CONTAINER_ARRIVAL_AT_FIRST_POL: 'Container arrival at first POL (Gate in)',
    CONTAINER_LOADED_AT_FIRST_POL: 'Container loaded at first POL',
    VESSEL_DEPARTURE_FROM_FIRST_POL: 'Vessel departure from first POL',
    VESSEL_ARRIVAL_PORT: 'Vessel arrival at T/S port',
    CONTAINER_DISCHARGE_PORT: 'Container discharge at T/S port',
    TRANSSHIPMENT_DELAY: 'Transshipment delay',
    CONTAINER_LOADED_AT_PORT: 'Container loaded at T/S port',
    VESSEL_DEPARTURE_FROM_TS: 'Vessel departure from T/S',
    VESSEL_ARRIVAL_AT_FINAL_POD: 'Vessel arrival at final POD',
    CONTAINER_DISCHARGE_AT_FINAL_POD: 'Container discharge at final POD',
    CONTAINER_DEPARTURE_FROM_FINAL_POD: 'Container departure from final POD (Gate out)',
    CONTAINER_DELIVERY_TO_CONSIGNEE: 'Container delivery to consignee',
    CONTAINER_EMPTY_RETURN_TO_DEPOT: 'Container empty return to depot',
    IN_TRANSIT: 'In Transit',
    ETA_UPDATE: 'ETA Update',
    UPDATE_SELECTED_NO_CONTAINER_SELECTED: 'Select some containers to update',
    UPDATE_SELECTED_CONTAINER_SELECTED_NO_TRACKING:
        'No tracked containers to update, start tracking these containers to get updates',
    TRACK_SELECTED_ALREADY_TRACKING: 'This container(s) is already being tracked.',
    TRACK_SELECTED_NO_CONTAINER_SELECTED: 'Select some containers to track.',
    TRACK_CONTAINER_CONFIRM: 'Are you sure want to track this container?',
    UNTRACK_CONTAINER_CONFIRM: 'Are you sure want to stop tracking this container?',
    UPDATE_ALL_CONFIRMATION_MESSAGE: 'Update all tracked containers?',
    UPDATE_SELECTED_CONFIRMATION_MESSAGE: 'Update selected tracked containers?',
    CONFIRM_DIALOG_TITLE: 'Confirm',
    SUCCESS_DIALOG_TITLE: 'Success',
    WARNING_DIALOG_TITLE: 'Warning',
    ERROR_DIALOG_TITLE: 'Error',
    ERROR_DIALOG_DESCRIPTION: 'An error occurred. Contact Support if the error persists',
    INFO_DIALOG_DESCRIPTION: 'Warning',
    TRACKING_PROGRESS: 'Tracking in Progress',
    STATUS_TRACKING_PROGRESS: 'Tracking in Progress',
    STATUS_CALL_TIME_OUT: 'Request time exceeded.Click refresh or Try again later.',
    STATUS_OK: 'Request completed successfully. There is information on containers and events.',
    STATUS_SEALINE_NO_RESPONSE: 'An error occurred while executing the request. The line is not responding',
    STATUS_SEALINE_HASNT_PROVIDE_INFO:
        'Request completed successfully. The line replied that nothing was found for this request',
    STATUS_NO_CONTAINERS: 'Request completed successfully. The line did not provide container information.',
    STATUS_NO_EVENTS:
        'Request completed successfully. The line provided information on containers, but did not provide information on events.',
    STATUS_WRONG_PARAMETERS: 'Your request is missing required parameters.',
    STATUS_API_KEY_WRONG: 'Unexpected error.',
    STATUS_API_KEY_ACCESS_DENIED: 'Unexpected error.',
    STATUS_API_KEY_EXPIRED: 'Unexpected error.',
    STATUS_API_KEY_LIMIT_REACHED: 'Unexpected error.',
    STATUS_API_KEY_RATE_LIMIT: 'Unexpected error.',
    STATUS_WRONG_TYPE:
        'The specified document type is invalid. See the description of the type parameter in the Query Parameters table.',
    STATUS_WRONG_NUMBER: 'Invalid bill of lading or booking number indicated.',
    STATUS_WRONG_SEALINE: 'Invalid carrier alpha code (SCAC) specified.',
    STATUS_SEALINE_NOT_SUPPORTED: 'No support for this carrier.',
    STATUS_UNEXPECTED_ERROR: 'Unexpected error.',
    STATUS_UNKNOWN_ERROR: 'Unknown error.',
    STATUS_AUTO_CANT_DETECT_SEALINE: 'Sealine could not be detected',
    STATUS_SEALINE_UNDER_MAINTENANCE: 'Sea line under maintenance.',
    NO_CONTAINERS_FOUND_MSG_001: 'No containers found in this shipment. Select another shipment and try again.',
    NO_NEW_CONTAINERS_ADDED_MSG_002_003: 'No containers added. All selected containers are already present.',
    NO_CONTAINERS_ADDED_YET_MSG_004:
        'There are not containers added to the extension yet. Select some shipments in Magaya and click on "Track in Container Tracking".',
    NO_CONTAINERS_TRACKING_YET_MSG_005:
        'There are not tracked containers. Select some shipments in Magaya and click on "Track in Container Tracking".',
    CONTAINERS_ERROR_MSG_009: 'Some containers contain errors and could not be loaded.',
    CONTAINERS_ERRORS_AND_CONTAINERS_EXISTING:
        'Some containers contain errors and could not be loaded. Others are already present.',
    SOME_CONTAINERS_EXISTING: 'Some containers are already present.',
    REMOVE_CONTAINERS_CONFIRM: 'Remove selected containers?',
    REMOVE_CONTAINERS_ERROR: 'Some containers could not be removed. Try again later.',
    REMOVE_NO_CONTAINER_SELECTED: 'Select some containers to remove.',
    ERROR_BUTTON_MORE_DETAILS: 'Click here to view more details',
    ISSUES_FOUND: 'Unexpected error.',
    INFO_SCREEN_TEXT_1:
        'With this extension you will be able to track containers directly inside Magaya from more than 50 sealines all over the world and stay updated of the related events in the shipment panel and LiveTrack. ',
    INFO_SCREEN_TEXT_2:
        'This is paid feature of Magaya Explorer, and in order to activate it, contact your Customer Success Manager or Magaya Support via Page one or by calling +1 786.845.9150.',
    INFO_SCREEN_CLOSE_WINDOW: 'Close this window',
    CS_CONNECTION_ERROR: 'There was a problem contacting the Central Service. Try again later.',
    NO_ARRIVAL_DATE: 'Not available',
    VIEW_ALL_CONTAINERS: 'View all containers',
    VIEW_TRACKED_CONTAINERS: 'View tracked containers only',
    VIEW_LATEST_CONTAINERS: 'View the latest containers uploaded to the extension.',
    REQUEST_NEW_DATA: 'Request new data of selected containers',
    TRACK_SELECTED_CONTAINERS: 'Track selected containers',
    REMOVE_SELECTED_CONTAINERS: 'Remove selected containers',
    REFRESH_DATA: "Refresh table's data",
    LEARN_MORE: 'Learn more',
    LAST_EVENT: 'Last Event',
    TERMINAL_INFO: 'Terminal Information',
    NO_TERMINAL_INFO: 'Terminal Information is not supported for this SCAC code',
    NO_TERMINAL_DATA: 'Your subscription type does not support terminal data.',
    LEARN_MORE_TERMINAL: 'Learn more about the terminal data',
    TERMINAL: 'Terminal',
    PORT_OF_DESTINATION: 'Port of Destination',
    PICKUP_APPOINTMENT: 'Pickup Appointment',
    AVAILABLE_PICKUP: 'Available for Pick up',
    PICKUP_FREE: 'Pickup last free day',
    PENDING: 'Pending',
    HOLD_TERMINAL: 'Hold at Terminal',
    FEES: 'Fees',
    ACTUAL_EVENTS: 'Actual Events',
    ESTIMATED_EVENTS: 'Estimated Events',
    ACTUAL: 'Actual',
    CURRENT: 'Current',
    ESTIMATED: 'Estimated',
    TERMINAL_HISTORY: 'Container History at Terminal',
    HOLDS: 'Holds',
    LOCATION: 'Location',
    DATE: 'Date',
    NEW_VERSION: 'New version available',
    NEW_VERSION_NOTIF: 'There is a new version available.',
    ERROR_TYPE: 'Error Type',
    CARRIER_SCAC_CODE: 'Carrier & SCAC Code'
};

export default locale;
