/* eslint-disable prefer-template */
import React from 'react';
import { useTranslation } from 'react-i18next';

const PortOfLoading = props => {
    const { t } = useTranslation('containersApp');
    const getDateFormated = dateCSharpFormat => {
        const date = new Date(Date.parse(dateCSharpFormat));

        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const hours = ('0' + date.getHours()).slice(-2);
        const minutes = ('0' + date.getMinutes()).slice(-2);

        return `${month}/${day}/${date.getFullYear()} ${hours}:${minutes}`;
    };

    return (
        <div className="port-of-lading-container">
            <div className="port-of-lading-container__header">
                <div className="port-of-lading-container__header__caption">
                    <span className="port-of-lading-container__header__caption__header">
                        {t('PORT_OF_DESTINATION')}
                    </span>
                    <span className="port-of-lading-container__header__caption__division"> | </span>
                    <span className="port-of-lading-container__header__caption__text">
                        {props.container.destinationPortName}
                    </span>
                </div>
                <div className="port-of-lading-container__header__sub-caption">
                    <span className="port-of-lading-container__header__sub-caption__terminal-header">
                        {t('TERMINAL')} |{' '}
                    </span>
                    {props?.container?.terminalAttributes?.destinationTerminal?.name ?? (
                        <span className="terminal-pending">({t('PENDING')})</span>
                    )}
                    <span className="port-of-lading-container__header__sub-caption__terminal-header"> | </span>
                    {props?.container?.terminalAttributes?.destinationTerminal?.firmsCode || '-'}
                </div>
            </div>
            <div className="port-of-lading-container__body">
                <div className="port-of-lading-container__body__left">
                    <div className="port-of-lading-container__body__left__content">
                        <h3 className="port-of-lading-container__body__middle__content__header">
                            {t('PICKUP_APPOINTMENT')}
                        </h3>
                        <span className="port-of-lading-container__body__middle__content__text">
                            {props && props.container && props.container.pickupAppointmentAt
                                ? getDateFormated(props.container.pickupAppointmentAt)
                                : '—/—/—'}
                        </span>
                    </div>
                </div>
                <div className="port-of-lading-container__body__middle">
                    <div className="port-of-lading-container__body__middle__content">
                        <h3 className="port-of-lading-container__body__middle__content__header">{t('PICKUP_FREE')}</h3>
                        <span className="port-of-lading-container__body__middle__content__text">
                            {props && props.container && props.container.pickupLfd
                                ? getDateFormated(props.container.pickupLfd)
                                : '—/—/—'}
                        </span>
                    </div>
                </div>
                <div className="port-of-lading-container__body__right">
                    <div className="port-of-lading-container__body__right__content">
                        <h3 className="port-of-lading-container__body__middle__content__header">
                            {t('AVAILABLE_PICKUP')}
                        </h3>
                        <span className="port-of-lading-container__body__middle__content__text">
                            {props.container.availableForPickup === true
                                ? 'Yes'
                                : props.container.availableForPickup === false
                                ? 'No'
                                : '-'}
                        </span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PortOfLoading;
